import React from "react";
import theme from "theme";
import { Theme, Box, Text, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdFace, MdArrowDownward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"draft"} />
		<Helmet>
			<title>
				Дизайнер Артём Жигалин
			</title>
			<meta name={"description"} content={"Делаю вдумчивый выразительный и работающий дизайн. Мой фокус это SaaS-проекты"} />
			<meta property={"og:title"} content={"Дизайнер Артём Жигалин"} />
			<meta property={"og:description"} content={"Делаю вдумчивый выразительный и работающий дизайн. Мой фокус это SaaS-проекты"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-512.png?v=2022-06-20T14:58:50.557Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-32.png?v=2022-06-18T12:04:38.725Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-270.png?v=2022-06-18T12:05:00.760Z"} />
		</Helmet>
		<Box
			quarkly-title="FloatBg"
			min-height="480px"
			background="#4262F2 url(https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/webask-bg-1.png?v=2022-06-14T12:21:32.410Z) center/cover"
			max-width="1600px"
			width="100%"
			margin="0px auto 0px auto"
		/>
		<Components.QuarklycommunityKitCarousel
			width="100%"
			slidesProp="3"
			aspectRatio="16:9"
			border-width="2px"
			border-style="solid"
			border-color="--color-lightD2"
			durationProp="0.5s"
			functionProp="ease-in-out"
			max-width="1280px"
		>
			<Override slot="Slide Content" position="static" transform="none" />
			<Override slot="Slide Image 1" src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-webask-builder-default-1.png?v=2022-04-12T13:22:10.482Z" />
			<Override slot="Slide Image" object-fit="contain" object-position="50% 0%" />
			<Override slot="Slide Image 2" src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-webask-builder-adding-control-2.png?v=2022-04-12T13:22:10.470Z" />
			<Override slot="Slide Image 3" src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-webask-builder-control-settings-3.png?v=2022-04-12T13:22:10.473Z" />
			<Override slot="Arrow Next" background="transparent" />
			<Override slot="Arrow Icon" color="--darkL2" />
			<Override slot="Point Icon" color="--grey" opacity="0.6" />
			<Override slot="Point Icon :active" opacity="1" />
			<Override slot="Arrow" hover-background="transparent" background="transparent" />
			<Override slot="Arrow Prev" background="transparent" />
			<Override slot="Slide" display="flex" flex-direction="column" justify-content="flex-end" />
			<Override slot="Slide Link" display="none" />
			<Override slot="Slide Head" display="none" />
			<Override slot="Slide Text" font="--base" color="--darkL2" display="none" />
			<Override slot="Points" margin="20px 0px 0px 0px" />
		</Components.QuarklycommunityKitCarousel>
		<Box
			min-width="100px"
			quarkly-title="100vh"
			display="flex"
			flex-direction="column"
			height="100vh"
			min-height="660px"
			sm-padding="0px 24px 0px 24px"
		>
			<Components.QuarklycommunityKitScrollAnimationCustom
				transformStart="skew(0deg, 0deg)"
				transformEnd="skew(20deg, 20deg)"
				transformEnabled="on"
				opacityEnabled="on"
				opacityStart="1"
				opacityEnd="0"
				endBorder="0"
				boxShadowEnabled="on"
			>
				<Text margin="0px 0px 0px 0px" color="--darkL2" font="--title">
					Продуктовый
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" font="--title">
					дизайнер
				</Text>
			</Components.QuarklycommunityKitScrollAnimationCustom>
			<Components.QuarklycommunityKitScrollAnimationCustom
				transformEnabled="on"
				transformStart="rotateZ(0deg)"
				transformEnd="rotateZ(480deg)"
				position="absolute"
				top="253px"
				right="53px"
				endBorder="0"
				opacityEnabled="on"
				opacityStart="1"
				opacityEnd="0"
			>
				<Icon
					category="md"
					icon={MdFace}
					size="194px"
					bottom="auto"
					height="200px"
					left="auto"
					right="53px"
					top="253px"
					width="200px"
					position="static"
					color="--red"
				/>
			</Components.QuarklycommunityKitScrollAnimationCustom>
			<Components.QuarklycommunityKitScrollAnimationCustom
				margin="auto 0px 64px 0px"
				opacityEnd="0"
				opacityStart="1"
				opacityEnabled="on"
				endBorder="20"
				transformStart="scale(1)"
				transformEnd="scale(0.9)"
				transformEnabled="on"
				startBorder="60"
				mix-blend-mode="exclusion"
			>
				<Text
					color="--light"
					text-transform="uppercase"
					letter-spacing="1px"
					font="--lead"
					text-align="center"
					margin="16px 0px 7px 0px"
				>
					SCroll
				</Text>
				<Components.QuarklycommunityKitAnimation
					animation="Juggle"
					timingFunction="ease-in-out"
					display="flex"
					justify-content="center"
					duration="1s"
				>
					<Icon category="md" icon={MdArrowDownward} size="32px" color="--light" />
				</Components.QuarklycommunityKitAnimation>
			</Components.QuarklycommunityKitScrollAnimationCustom>
		</Box>
		<Box min-width="100px" quarkly-title="100vh" position="relative" sm-padding="0px 24px 0px 24px">
			<Components.QuarklycommunityKitScrollAnimationCustom
				transformStart="skew(0deg, 0deg)"
				transformEnd="skew(20deg, 20deg)"
				transformEnabled="on"
				opacityEnabled="on"
				opacityEnd="0"
				opacityStart="1"
				endBorder="0"
				boxShadowEnabled="on"
				startTrigger="bottom"
				startBorder="60"
			>
				<Text margin="0px 0px 0px 0px" color="--darkL2" font="--title">
					Портфолио
				</Text>
			</Components.QuarklycommunityKitScrollAnimationCustom>
			<Box min-width="100px" min-height="100px" margin="90px 0px 90px 0px" max-width="360px">
				<Text
					color="--light"
					text-transform="uppercase"
					letter-spacing="1px"
					font="--lead"
					text-align="left"
				>
					I would like to work in a small team on SaaS and micro-SaaS web projects.{" "}
				</Text>
			</Box>
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"622f01726ba4ec00186af2fd"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script type={"text/javascript"} place={"endOfHead"} rawKey={"62b188e99e03ae0e7196a484"}>
				{"(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n   (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n\n   ym(31525948, \"init\", {\n        clickmap:true,\n        trackLinks:true,\n        accurateTrackBounce:true\n   });"}
			</script>
		</RawHtml>
	</Theme>;
});